






import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterTile } from '@/typings';
import Tile from './Tile.vue';

@Component({ components: { Tile } })
export default class TileList extends Vue {
  @Prop({ type: Array, required: true }) tileList!: RouterTile[];
}
