










import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterTile } from '@/typings';

@Component({})
export default class Tile extends Vue {
  @Prop({ type: Object, required: true }) tile!: RouterTile;

  get ariaLabel(): string {
    return `Otwórz ${this.tile.title} (link przechodzi do podstrony w tym samym oknie)`;
  }
}
